import { useMemo } from "react";
import { DA_CartLinePrice } from "../interfaces/price.interface";
import { useCart } from "../contexts/cart/cart.context";

type PriceDataResponse = (DA_CartLinePrice | undefined)[];

/**
 * Use this function to map orderLines and prices.
 * The return value is an array of prices in the same order as the order lines.
 */
export const useOrderLinesPriceData = () => {
    const { orderLines, priceData } = useCart();

    return useMemo(() => {
        return orderLines.reduce<PriceDataResponse>((result, orderLine) => {
            const priceLine = priceData?.lines.find(
                ({ orderLineId }) => orderLineId === orderLine.id
            );
            return result.concat(priceLine || undefined);
        }, []);
    }, [orderLines, priceData]);
};
