import React from "react";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { ReactComponent as DanishAgroLogo } from "@danishagro/shared/src/assets/svg/danish-agro-logo.svg";
import { ReactComponent as VilofarmLogo } from "@danishagro/shared/src/assets/svg/vilofarm-logo.svg";
import { useAppData } from "../../../contexts/appData.context";
import { useTranslations } from "../../../contexts/translations/translations.context";
import S from "./SimpleHeader.module.scss";

export const DA_SimpleHeader = () => {
    const { siteVariant } = useAppData();
    const { getDictionaryString } = useTranslations();

    return (
        <header className={cn(S.header)}>
            <DA_Container>
                <div className={S.navigationWrapper}>
                    <div className={S.logoWrapper}>
                        <DA_BasicLink
                            href="/"
                            ariaLabel={getDictionaryString("frontpage")}
                            className={S.logoLink}
                        >
                            {siteVariant.alias === "danish-agro" && <DanishAgroLogo />}
                            {siteVariant.alias === "vilofarm" && <VilofarmLogo />}
                        </DA_BasicLink>
                    </div>
                    <div className={S.tagline}>
                        <strong>{getDictionaryString("SimpleHeaderHeading")}</strong>
                        <span>{getDictionaryString("SimpleHeaderText")}</span>
                    </div>
                </div>
            </DA_Container>
        </header>
    );
};
