// GTM Tracking
import { useCallback } from "react";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";

type TrackMultiProductsProps = {
    productName: string;
    price?: number;
    productNumber: string;
    currency?: string;
    category?: string;
    brand?: string;
    listId?: string;
    listName?: string;
    quantity?: number;
};

export const useProducts = (products: TrackMultiProductsProps[]) => {
    const formatPrice = useCallback(
        (price: number) => formatNumber(price, "en-GB", { decimals: 2 }),
        []
    );

    const trackProducts = useCallback(
        (eventName: string) => {
            window.dataLayer = window.dataLayer || [];
            // Clear the previous ecommerce object
            window.dataLayer?.push({ ecommerce: null });

            const items = products.map((product, index) => {
                return {
                    item_id: product.productNumber,
                    item_name: product.productName,
                    price: formatPrice(product.price) || "",
                    quantity: product.quantity || 1,
                    discount: 0.0,
                    currency: product.currency,
                    item_category: product.category || "",
                    item_brand: product.brand || "",
                    item_list_id: product.listId || "",
                    item_list_name: product.listName || "",
                    index: index,
                    // Add more fields here as needed...
                };
            });

            window.dataLayer.push({
                event: eventName,
                ecommerce: {
                    items: items,
                },
            });
        },
        [products, formatPrice]
    );

    return trackProducts;
};
