import React, { useEffect, useMemo } from "react";
import { animated, Transition } from "react-spring";
import { _CartItem } from "@organisms/CartList/Item/CartItem.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useProducts } from "@danishagro/shared/src/gtm/useProducts";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { DA_CartListProps } from "./CartList.props";
import S from "./CartList.module.scss";

export const DA_CartList = ({ ...props }: DA_CartListProps) => {
    const { getDictionaryString } = useTranslations();

    const { priceData } = useCart();
    const { showPrices } = useAppData();

    const texts = useMemo(
        () => ({
            name: getDictionaryString("name", { uppercaseFirst: true }),
            date: getDictionaryString("latest delivery date", {
                uppercaseFirst: true,
            }),
            amount: getDictionaryString("amount", { uppercaseFirst: true }),
            comment: getDictionaryString("comment", { uppercaseFirst: true }),
            price: getDictionaryString("price", { uppercaseFirst: true }),
        }),
        [getDictionaryString]
    );

    // GTM Tracking
    // TODO: Wait for priceService, and add price
    const transformedItems = useMemo(() => {
        return props.items.map((item) => {
            // Extract price for each line
            const priceInfo = priceData?.lines.find((line) => line?.orderLineId === item?.id);

            // Extract the category from the URL
            const category = item?.url?.split("/").slice(0, -1).join("_");

            return {
                productName: item.productName,
                productNumber: item.productNumber,
                price: priceInfo ? priceInfo.price.priceWithoutVat : 0, // Use priceWithVat from priceData
                currency: "DKK",
                quantity: item.quantity,
                category,
                // ... other properties
            };
        });
    }, [props.items, priceData]);

    const trackProducts = useProducts(transformedItems);

    useEffect(() => {
        // Trigger GTM tracking only if priceData is available and has lines
        if (priceData && priceData.lines && priceData.lines.length > 0) {
            trackProducts("view_cart");
        }
    }, [trackProducts, priceData, props.itemPrices]);

    return (
        <section className={cn(S.cartList, props.noShadow || S.withShadow, props.className)}>
            {/** Header */}
            {!props.readOnly ? (
                <div className={S.header} aria-hidden={true}>
                    <div className={S.headerItem}>{texts.name}</div>
                    <div className={S.headerItem}>{showPrices && texts.date}</div>
                    <div className={S.headerItem}>{texts.amount}</div>
                    <div className={S.headerItem}>{texts.comment}</div>
                    <div className={S.headerItem}>{showPrices && texts.price}</div>
                </div>
            ) : null}
            <Transition
                items={props.items}
                from={{ opacity: 1 }}
                leave={{ opacity: 0 }}
                keys={(item) => item.id}
            >
                {(style, item) => (
                    <animated.div className={S.item} style={style}>
                        <_CartItem
                            key={item.id}
                            {...item}
                            linePrice={
                                props.itemPrices?.find(
                                    ({ orderLineId }) => orderLineId === item.id
                                ) || undefined
                            }
                            hideDeliveryOptions={props.hideDeliveryOptions}
                            hideDriverMessage={props.hideDriverMessage}
                            hideDriverMessageButton={props.hideDriverMessageButton}
                            farmInTimeReferenceId={props.farmInTimeReferenceId}
                            readOnly={props.readOnly}
                            isReceiptPage={props.isReceiptPage}
                        />
                    </animated.div>
                )}
            </Transition>
        </section>
    );
};
