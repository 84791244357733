import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_CategoryLink } from "@interfaces/categoryLink.interface";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import S from "./LinkSlider.module.scss";

export interface LinkSliderProps {
    title: string;
    items: DA_CategoryLink[];

    onCategoryLinkClick?: (item: DA_CategoryLink) => void;
}

export const DA_LinkSlider = (props: LinkSliderProps) => {
    const onCategoryLinkClicked = (item: DA_CategoryLink) => {
        if (typeof props.onCategoryLinkClick !== "undefined") {
            props.onCategoryLinkClick(item);
        }
    };

    return (
        <>
            <div className={S.titleWrapper}>
                <span className={S.title}>{props.title}</span>
                <span className={S.scrollIconWrapper}>
                    <DA_Icon name={DA_IconNames.ScrollHorizontal} />
                </span>
            </div>

            <ul className={S.list}>
                {props.items?.map((item) => {
                    const isActiveClass = item.currentPage ? S.isActive : undefined;

                    return (
                        <li className={cn(S.listItem, isActiveClass)} key={item.id}>
                            <DA_BasicLink
                                className={S.listItemLink}
                                href={item.url}
                                onClick={() => onCategoryLinkClicked(item)}
                            >
                                {item.name}
                            </DA_BasicLink>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};
