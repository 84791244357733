import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { DA_UspList } from "@organisms/UspList/UspList.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { usePage } from "@danishagro/shared/src/contexts/page.context";
import { getPage } from "@helpers/getPage.helper";
import { DA_CategorySlider } from "@organisms/CategorySlider/CategorySlider.component";
import { DA_Hero } from "@danishagro/shared/src/components/organisms/Hero/Hero.component";
import ComponentErrorBoundary from "@danishagro/shared/src/components/molecules/ErrorBoundary/ComponentErrorBoundary.component";
import { DA_NewPageData } from "@interfaces/newPageData.interface";
import { DA_NewCategoriesModuleData } from "@interfaces/contentModules/newPageData/categories.interface";
import { DA_NewBlockSpotsModuleData } from "@interfaces/contentModules/newPageData/spots.interface";
import { getAbsoluteUrl } from "@danishagro/shared/src/components/organisms/SiteHeader/LanguageSelector/hooks/getAbsolutePath.hook";
import { DA_NewHeroModuleData } from "@danishagro/shared/src/interfaces/hero.interface";
import { DA_ProductItemSlider } from "@organisms/ProductItemSlider/ProductItemSlider.component";
import {
    PopularProductsBuilder,
    ProblemDetailsError,
    ProductResult,
    RecentlyViewedProductsBuilder,
} from "@relewise/client";
import { useRelewise } from "@danishagro/shared/src/contexts/relewise.context";
import { DA_NavigationPageSimple } from "@danishagro/shared/src/interfaces/navigationPage.interface";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";

export const DA_HomePage = () => {
    const { setPageIsLoading, allPages } = usePage();
    const [pageData, setPageData] = useState<DA_NewPageData>();
    const { getRecommender, mapProduct, relewiseUser } = useRelewise();
    const [firstLevelPages, setFirstLevelPages] = useState<DA_NavigationPageSimple[]>();
    const [popularProducts, setPopularProducts] = useState<ProductResult[] | null | undefined>();
    const [recentlyViewedProducts, setRecentlyViewedProducts] = useState<
        ProductResult[] | null | undefined
    >();
    const getFirstLevelPages = useCallback((pages: DA_NavigationPageSimple[]) => {
        const firstLevelPages = pages.filter(
            (page) => page.path.split("/").length === 2 && page.imageUrl
        );
        setFirstLevelPages(firstLevelPages);
    }, []);

    const settings = {
        language: "da",
        currency: "DKK",
        displayedAtLocation: "Frontpage",
        user: relewiseUser(),
    };

    const recentlyViewedProductsBuilder = new RecentlyViewedProductsBuilder(settings)
        .setSelectedProductProperties({
            displayName: true,
            pricing: true,
            allData: true,
            brand: true,
            allVariants: true,
        })
        .setNumberOfRecommendations(6)
        .allowFillIfNecessaryToReachNumberOfRecommendations(false)
        .allowReplacingOfRecentlyShownRecommendations(false)
        .recommendVariant(true)
        .prioritizeDiversityBetweenRequests(false);

    const popularProductsbuilder = new PopularProductsBuilder(settings)
        .setSelectedProductProperties({
            displayName: true,
            pricing: true,
            allData: true,
            brand: true,
            allVariants: true,
        })
        .setNumberOfRecommendations(6)
        .allowFillIfNecessaryToReachNumberOfRecommendations(false)
        .allowReplacingOfRecentlyShownRecommendations(false)
        .recommendVariant(true)
        .basedOn("MostViewed")
        .sinceMinutesAgo(30240)
        .prioritizeDiversityBetweenRequests(false);

    useEffect(() => {
        getFirstLevelPages(allPages);
    }, [getFirstLevelPages, allPages]);

    useEffect(() => {
        if (globalThis.isAuthenticated) {
            getRecommender()
                .recentlyViewedProducts(recentlyViewedProductsBuilder.build())
                .then((result) => {
                    setRecentlyViewedProducts(result?.recommendations);
                })
                .catch((error: ProblemDetailsError) => console.error(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getRecommender()
            .recommendPopularProducts(popularProductsbuilder.build())
            .then((result) => {
                setPopularProducts(result?.recommendations);
            })
            .catch((error: ProblemDetailsError) => console.error(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getImageObject = (data: DA_NewHeroModuleData) => {
        const { image } = data.content.props;

        if (image.props.umbracoFile?.includes("assetID")) {
            const imageWidth: number = 1400;
            const imageHeight: number = 600;
            const imageQuality: number = 80;

            const digizuiteSrc = image.props.umbracoFile
                .replace("{QUALITY}", `${imageQuality}`)
                .replace("{WIDTH}", `${imageWidth}`)
                .replace("{HEIGHT}", `${imageHeight}`);

            return {
                url: digizuiteSrc,
                width: imageWidth,
                height: imageHeight,
                alt: image.name,
            };
        } else if (image?.props?.umbracoFile) {
            return {
                url: getAbsoluteUrl(image?.props?.umbracoFile),
                width: image.props?.umbracoWidth,
                height: image.props?.umbracoHeight,
                alt: image.name,
            };
        } else {
            return null; // or handle the case where no image is available
        }
    };

    useEffect(() => {
        setPageIsLoading(true);
        getPage()
            .then((page) => {
                setPageData(page);
                setPageIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching page content:", error);
                setPageIsLoading(false);
            });
    }, [setPageIsLoading]);

    return (
        <>
            <Helmet>
                <title>{pageData?.name}</title>
                <meta name="description" content={pageData?.metaDescription} />
            </Helmet>

            <div className="frontpage">
                <div className="frontpage-content">
                    {pageData?.props?.hero?.map((item, index) => {
                        const heroImageObject = getImageObject(item);

                        // Construct Hero Image
                        const heroImage = {
                            ...heroImageObject,
                        };

                        // Construct Hero Button
                        const heroButtonObject = () => {
                            const first = pageData?.props.hero[0].content.props.buttons[0];
                            return {
                                title: first.content.props.link.name,
                                href: first.content.props.link.url,
                                target: first.content.props.link.target || "_self",
                                conventionalRouting: true,
                            };
                        };

                        return (
                            <div className="frontpage-hero" key={index}>
                                <ComponentErrorBoundary showNothing componentName="Hero">
                                    <DA_Hero
                                        headline={item.content.props.headline || ""}
                                        text={item.content.props.text || ""}
                                        image={{
                                            src: heroImage.url,
                                            alt: heroImage.alt || "",
                                        }}
                                        link={heroButtonObject()}
                                        fullWidthMobile
                                    />
                                </ComponentErrorBoundary>
                            </div>
                        );
                    })}

                    {/* kategorier */}
                    {pageData?.props?.modules?.map((item, index) => {
                        if (item.content.alias === "blockCategories" && firstLevelPages) {
                            const data = item as DA_NewCategoriesModuleData;

                            const createObject = (navigationObject: DA_NavigationPageSimple) => {
                                return {
                                    title: navigationObject.name,
                                    url: navigationObject.path,
                                    imageAltText: navigationObject.name,
                                    image: `${navigationObject.imageUrl}/${ImageConfig.CategoryImage}`,
                                };
                            };

                            return (
                                <div className="frontpage-categories" key={index}>
                                    <ComponentErrorBoundary
                                        showNothing
                                        componentName="Category Slider"
                                    >
                                        <DA_CategorySlider
                                            key={index}
                                            title={data.content.props.headline}
                                            items={firstLevelPages.map((page) =>
                                                createObject(page)
                                            )}
                                        />
                                    </ComponentErrorBoundary>
                                </div>
                            );
                        }

                        if (
                            item.content.alias === "blockProducts" &&
                            item.content.props.type === "Latest" &&
                            recentlyViewedProducts?.length > 0
                        ) {
                            return (
                                <div className="frontpage-most-seen-products" key={index}>
                                    <ComponentErrorBoundary
                                        showNothing
                                        componentName="LastSeenSlider"
                                    >
                                        <DA_ProductItemSlider
                                            key={item.content.key}
                                            title={item.content.props.headline}
                                            items={recentlyViewedProducts?.map((product) =>
                                                mapProduct(product)
                                            )}
                                        />
                                    </ComponentErrorBoundary>
                                </div>
                            );
                        }

                        if (
                            item.content.alias === "blockProducts" &&
                            item.content.props.type === "Popular" &&
                            popularProducts?.length > 0
                        ) {
                            return (
                                <div className="frontpage-most-seen-products" key={index}>
                                    <ComponentErrorBoundary
                                        showNothing
                                        componentName="MostSeenSlider"
                                    >
                                        <DA_ProductItemSlider
                                            key={item.content.key}
                                            title={item.content.props.headline}
                                            items={popularProducts?.map((product) =>
                                                mapProduct(product)
                                            )}
                                        />
                                    </ComponentErrorBoundary>
                                </div>
                            );
                        }

                        if (item.content.alias === "blockSpots") {
                            const spotItem = item as DA_NewBlockSpotsModuleData;

                            return (
                                <div className="frontpage-spots" key={index}>
                                    <ComponentErrorBoundary showNothing componentName="USP list">
                                        <DA_UspList
                                            key={index}
                                            header={spotItem.content.props.headline}
                                            description={spotItem.content.props.text}
                                            items={spotItem.content.props?.items.map((item) => ({
                                                icon: item.content.props.media[0].content.props
                                                    .icon as DA_IconNames,
                                                text: item.content.props.headline,
                                                subText: item.content.props.text,
                                            }))}
                                            mobileFullWidth
                                        />
                                    </ComponentErrorBoundary>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </>
    );
};
