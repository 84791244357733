import React, { useId, useMemo, useRef, useState } from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_InlineLink } from "@danishagro/shared/src/components/atoms/InlineLink/InlineLink.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import {
    _UrgentDeliverySection,
    _UrgentDeliverySectionProps,
} from "@organisms/UrgentDelivery/components/UrgentDeliverySection/UrgentDeliverySection.component";
import { getUrl } from "@danishagro/shared/src/helpers/getUrl.helper";
import { ExpressDeliveryModes } from "@danishagro/shared/src/interfaces/expressDeliveryModes.interface";
import { CROP_PROTECTION_DELIVERY_MODE_DEFAULT } from "@danishagro/shared/src/contexts/cart/cart.context";
import S from "./UrgentDelivery.module.scss";

export interface DA_UrgentDeliveryProps {
    defaultSelections: ExpressDeliveryModes;
    cropProtectionProducts: number;
    urgentProducts: number;
    deliveryOptions?: {
        active: boolean;
        id: string;
        name: string;
    }[];
    onChange?: (selections: ExpressDeliveryModes) => void;
}

export const DA_UrgentDelivery = (props: DA_UrgentDeliveryProps) => {
    const [urgentDelivery, setUrgentDelivery] = useState(
        props.defaultSelections.isExpressDelivery || false
    );
    const [cropProtectionDelivery, setCropProtectionDelivery] = useState(
        props.defaultSelections.cropProtectionDeliveryMode || CROP_PROTECTION_DELIVERY_MODE_DEFAULT
    );
    const { getDictionaryString } = useTranslations();

    const urgentDeliveryTexts: _UrgentDeliverySectionProps["texts"] = useMemo(
        () => ({
            icon: DA_IconNames.Truck,
            title: getDictionaryString("urgent delivery"),
            text: getDictionaryString("urgent delivery text", {
                x:
                    `<strong>${props.urgentProducts}&nbsp;` +
                    (props.urgentProducts === 1
                        ? getDictionaryString("product").toLocaleLowerCase()
                        : getDictionaryString("products").toLocaleLowerCase()) +
                    "</strong>",
            }),
            titleColor: "blue",
        }),
        [getDictionaryString, props.urgentProducts]
    );

    const urgentDeliveryOptions = useRef([
        {
            id: useId(),
            text: getDictionaryString("urgent delivery"),
            tooltip: getDictionaryString("urgent delivery tooltip", {
                dontShowFallback: true,
            }),
        },
    ]);

    const cropProtectionTexts: _UrgentDeliverySectionProps["texts"] = useMemo(
        () => ({
            icon: DA_IconNames.PlantHand,
            title: getDictionaryString("crop protection delivery"),
            text: getDictionaryString("crop protection delivery text", {
                x:
                    `<strong>${props.cropProtectionProducts}&nbsp;` +
                    (props.cropProtectionProducts === 1
                        ? getDictionaryString("product").toLocaleLowerCase()
                        : getDictionaryString("products").toLocaleLowerCase()) +
                    "</strong>",
            }),
            titleColor: "green",
        }),
        [getDictionaryString, props.cropProtectionProducts]
    );

    const generateTooltip = (item) => {
        // Custom logic to generate tooltip based on item properties
        switch (item.id) {
            case "111":
                return getDictionaryString("crop protection delivery same day tooltip", {
                    dontShowFallback: true,
                });
            case "112":
                return getDictionaryString("crop protection delivery day to day tooltip", {
                    dontShowFallback: true,
                });
            default:
                return "";
        }
    };

    const cropProtectionOptions = useRef(
        props.deliveryOptions
            .filter((item) => item.active === true)
            .map((item) => {
                return {
                    id: item.id,
                    text: getDictionaryString(item.name),
                    tooltip: generateTooltip(item),
                    code: item.id,
                };
            })
    );

    return (
        <section>
            <header className={S.header}>
                <DA_Title h4 noMargin>
                    {getDictionaryString("urgent delivery title")}
                </DA_Title>
                {props.urgentProducts > 0 && (
                    <div className={S.appendage}>
                        <DA_InlineLink
                            title={getDictionaryString("urgent delivery deadlines link text")}
                            href={getUrl("termsAndConditionsOrderingDeadlines")}
                            icon={DA_IconNames.ExternalLink}
                            target="_blank"
                        />
                    </div>
                )}
            </header>

            {props.urgentProducts > 0 && (
                <_UrgentDeliverySection
                    texts={urgentDeliveryTexts}
                    options={urgentDeliveryOptions.current}
                    selected={urgentDelivery ? urgentDeliveryOptions.current[0].id : ""}
                    onChange={() => {
                        setUrgentDelivery((x) => {
                            const newValue = !x;
                            props.onChange({
                                cropProtectionDeliveryMode: cropProtectionDelivery,
                                isExpressDelivery: newValue,
                            });
                            return newValue;
                        });
                    }}
                />
            )}

            {props.cropProtectionProducts > 0 && (
                <_UrgentDeliverySection
                    texts={cropProtectionTexts}
                    options={cropProtectionOptions.current}
                    selected={
                        cropProtectionOptions.current.find((x) => x.code === cropProtectionDelivery)
                            ?.id
                    }
                    onChange={(id) => {
                        const clickedCode = cropProtectionOptions.current.find(
                            (x) => x.id === id
                        ).code;
                        // const newCode =
                        //     cropProtectionDelivery === clickedCode
                        //         ? CROP_PROTECTION_DELIVERY_MODE_DEFAULT
                        //         : clickedCode;
                        // Send empty string to reset cpDeliveryMode
                        const newCode = cropProtectionDelivery === clickedCode ? "" : clickedCode;

                        setCropProtectionDelivery(newCode);

                        props.onChange({
                            cropProtectionDeliveryMode: newCode,
                            isExpressDelivery: urgentDelivery,
                        });
                    }}
                />
            )}
        </section>
    );
};
