import React, { useCallback, useEffect, useRef, useState } from "react";
import { DA_QuantityPicker } from "@molecules/QuantityPicker/QuantityPicker.component";
import { DA_CartButton } from "@molecules/CartButton/CartButton.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useNavigate } from "react-router-dom";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { DA_NewProductSimple } from "@danishagro/shared/src/interfaces/productv2.interface";
import { DA_ProductListPrice } from "@molecules/ProductListPrice/ProductListPrice.component";
import { DA_Badge } from "@danishagro/shared/src/components/organisms/SiteHeader/Badge/Badge.component";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { DA_DeliveryInfoModalContent } from "@templates/DeliveryInfoModal/DeliveryInfoModal.component";
import { DA_WishListButton } from "@molecules/WishListButton/WishListButton.component";
import { useProductTracker } from "@danishagro/shared/src/gtm/useProductsTracker";
import { DA_InlineLink } from "@danishagro/shared/src/components/atoms/InlineLink/InlineLink.component";
import { B2bImageSrc } from "@danishagro/shared/src/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { useBottomSheet } from "@danishagro/shared/src/contexts/bottomSheet/bottomSheet.context";
import { DA_QuantityOnPalletOverlay } from "@danishagro/shared/src/modals/QuantityOnPalletShop/Overlay/Overlay.component";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { modalState } from "@danishagro/shared/src/enums/modalState.enum";
import S from "./ProductListItem.module.scss";

export type ProductListItemProps = DA_NewProductSimple & {
    categoryId?: string; // TODO: DELETE DEBUG DATA
    onSale?: boolean;
    eagerLoading?: boolean;

    "data-id"?: string;
    "data-name"?: string;
    "data-listid"?: string;
    "data-price"?: string;
    "data-category"?: string;
    "data-brand"?: string;
};

export const ProductListItem = React.forwardRef<HTMLElement, ProductListItemProps>((props, ref) => {
    const {
        entityId,
        hasVariants,
        variantEntityId,
        displayName,
        newProduct,
        imageUrl,
        url,
        hasHazardStatement,
        quantityOnPallet,
    } = props;

    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);
    const [awaitingResponse, setAwaitingResponse] = useState(false);

    const { showModal } = useModal();
    const { isMobileOrTablet } = useScreen();
    const { isFarmInTimeEnabled, showPrices } = useAppData();
    const { getDictionaryString } = useTranslations();
    const { addToCart } = useCart();
    const [choosedValue, setChoosedValue] = useState(false);
    const { presentBottomSheet } = useBottomSheet();

    const [modalViewState, setModalViewState] = useState<modalState>(modalState.hide);
    const [numberOfPallets, setNumberOfPallets] = useState<number>(0);
    const [totalPalletQuantity, setTotalPalletQuantity] = useState<number>(0);

    const isAvailableToBuy = useRef(!props.data?.redirectUrl?.value).current;

    // Shipping modal
    const openShippingInfoModal = useCallback(() => {
        showModal(<DA_DeliveryInfoModalContent />, {
            size: ModalSize.XXL,
        });
    }, [showModal]);

    const formatPrice = useCallback(
        (price: number) => formatNumber(price, "da-DK", { decimals: 2 }),
        []
    );

    const {
        "data-id": dataId,
        "data-name": dataName,
        "data-listid": dataListId,
        "data-category": dataCategory,
        "data-brand": dataBrand,
        // ... rest of your props
    } = props;

    // Extract the category from the URL
    const category = props?.url?.split("/").slice(0, -1).join("_");

    // GTM Tracking product clicked
    const trackProductClick = useProductTracker({
        ...props,
        price: formatPrice(props?.price?.priceWithoutVat),
        currency: props?.price?.currency,
        quantity: quantity,
        category: category,
        name: displayName,
        brand: dataBrand,
    });

    const latestQuantityRef = useRef(quantity);
    const quantityAsInt = parseInt(quantityOnPallet?.value);

    useEffect(() => {
        latestQuantityRef.current = quantity;
    }, [quantity]);

    const calculateRangeOffset = (quantityOnPallet: number) => {
        return Math.floor(quantityOnPallet * 0.25); // ±25% of quantityOnPallet
    };

    useEffect(() => {
        const intValue = quantity;
        setModalViewState(modalState.hide);

        if (!isNaN(quantity)) {
            const rangeOffset = calculateRangeOffset(quantityAsInt);
            for (let numberOfPallets = 1; numberOfPallets <= 100; numberOfPallets++) {
                const totalPalletQuantity = numberOfPallets * quantityAsInt;
                const lowerBound = totalPalletQuantity - rangeOffset || 0;
                const upperBound = totalPalletQuantity + rangeOffset || 0;

                if (
                    intValue !== totalPalletQuantity &&
                    intValue >= lowerBound &&
                    intValue <= upperBound
                ) {
                    setModalViewState(modalState.show);
                    setNumberOfPallets(numberOfPallets);
                    setTotalPalletQuantity(totalPalletQuantity);
                    return;
                } else if (intValue === totalPalletQuantity) {
                    setModalViewState(modalState.hide);
                    return;
                }
            }
        }
    }, [quantity, quantityAsInt]);

    const check = useCallback(
        (
            showModalState: modalState,
            defaultQuantityOnPallet: number,
            totalPalletQuantity: number,
            numberOfPallets: number
        ) => {
            return new Promise<number>((resolve) => {
                if (showModalState === modalState.show && !choosedValue) {
                    const handleSelection = (selectedValue) => {
                        setQuantity(selectedValue);
                        setChoosedValue(true);
                        resolve(selectedValue); // Resolve the promise after the state is updated with the new quantity
                    };

                    if (isMobileOrTablet) {
                        presentBottomSheet(
                            <DA_QuantityOnPalletOverlay
                                quantity={latestQuantityRef.current}
                                quantityOnPallet={totalPalletQuantity}
                                defaultQuantityOnPallet={defaultQuantityOnPallet}
                                numberOfPallets={numberOfPallets}
                                onSelection={handleSelection}
                            />,
                            {
                                id: "shop-quantity-bottom-sheet",
                                contentPadding: true,
                                headerTitle: getDictionaryString("shopquantityonpallettitle"),
                                snapPoints: ({ minHeight }) => [minHeight],
                                hideCloseButton: true,
                            }
                        );
                    } else {
                        showModal(
                            <DA_Modal title={getDictionaryString("shopquantityonpallettitle")}>
                                <DA_QuantityOnPalletOverlay
                                    quantity={latestQuantityRef.current}
                                    quantityOnPallet={totalPalletQuantity}
                                    defaultQuantityOnPallet={quantityAsInt}
                                    numberOfPallets={numberOfPallets}
                                    onSelection={handleSelection}
                                />
                            </DA_Modal>,
                            {
                                size: ModalSize.XS,
                                shouldCloseOnEsc: false,
                                shouldCloseOnOverlayClick: false,
                            }
                        );
                    }
                } else {
                    resolve(latestQuantityRef.current); // Resolve immediately if no modal is shown
                }
            });
        },
        [
            choosedValue,
            getDictionaryString,
            isMobileOrTablet,
            presentBottomSheet,
            quantityAsInt,
            showModal,
        ]
    );

    const addProductToCart = useCallback(
        async (modalViewState, defaultQuantityOnPallet, totalPalletQuantity, numberOfPallets) => {
            const selectedQuantity = await check(
                modalViewState,
                defaultQuantityOnPallet,
                totalPalletQuantity,
                numberOfPallets
            ); // Wait for check to complete and get the latest quantity

            setAwaitingResponse(true);
            if (hasHazardStatement || hasVariants) {
                navigate(url);
            } else {
                addToCart({
                    productId: variantEntityId,
                    quantity: selectedQuantity,
                }).finally(() => setAwaitingResponse(false));
            }
            // GTM Tracking
            trackProductClick("add_to_cart");
        },
        [
            addToCart,
            check,
            hasHazardStatement,
            hasVariants,
            navigate,
            trackProductClick,
            url,
            variantEntityId,
        ]
    );

    const onLinkClicked = () => {
        if (typeof window !== "undefined") {
            navigate(`${window.location.pathname}${window.location.search}`, {
                replace: true,
                state: {
                    scrollY: document.documentElement.scrollTop || document.body.scrollTop,
                },
            });
        }
        // // GTM Tracking the product click when the link is clicked
        trackProductClick("select_item");
    };

    return (
        <article
            className={cn(S.productListItem, newProduct && S.productListItemNew)}
            itemScope
            itemType="https://schema.org/Product"
            ref={ref}
            data-id={dataId}
            data-category={dataCategory}
            data-brand={dataBrand}
            key={variantEntityId}
            data-name={dataName}
            data-listid={dataListId}
            data-price={formatPrice(props?.price?.priceWithoutVat)}
        >
            <meta itemProp="sameAs" content={url} />

            <div className={S.imageWrapper}>
                {isFarmInTimeEnabled && globalThis.isAuthenticated && isAvailableToBuy && (
                    <div className={S.wishListButton}>
                        <DA_WishListButton
                            productId={entityId}
                            productName={dataName}
                            productImage={B2bImageSrc(imageUrl, ImageConfig.CategoryImage)}
                            variantId={variantEntityId}
                            productPageUrl={url}
                            quantityOnPallet={props.data?.quantityOnPallet?.value}
                            hasVariants={hasVariants}
                            isVariantSelected={() => {
                                navigate(url);
                            }}
                        />
                    </div>
                )}

                <DA_BasicLink href={url} className={S.imageLink} onClick={onLinkClicked}>
                    {
                        <div className={S.productImageWrapper}>
                            {/* TODO: Develop new image component */}
                            <img
                                className={S.productImage}
                                src={B2bImageSrc(imageUrl, ImageConfig.CategoryImage)}
                                alt=""
                                loading="lazy"
                            />
                        </div>
                    }
                </DA_BasicLink>

                {/* {newProduct && (
                        <DA_Badge
                        className={cn(S.badge, S.newProductBadge)}
                        size="normal"
                        text={getDictionaryString("new splat")}
                        />
                    )}

                    {videos && videos.length > 0 ? (
                        <DA_BasicLink href={`${url}#video`} className={S.videoLink}>
                        <DA_Icon style={{ color: "#fff" }} name={DA_IconNames.Play} />
                        </DA_BasicLink>
                    ) : null} */}
            </div>

            <div className={S.productDataWrapper}>
                <div>
                    <div className={S.productNumber}>
                        <DA_Text noPadding smallText>
                            {`${getDictionaryString("product number")} ${props.productNumber}`}
                        </DA_Text>
                    </div>
                    <div className={S.productName}>
                        <DA_BasicLink href={url} className={S.productNameLink}>
                            <span itemProp="name">{displayName}</span>
                        </DA_BasicLink>
                    </div>
                    {hasVariants && (
                        <div className={S.lightText}>{getDictionaryString("more variants")}</div>
                    )}
                </div>

                <div className={S.secondaryContent}>
                    {/** Energy Classification */}
                    {props.data?.energyEfficiencyArrows?.value &&
                        props.data?.energyEfficiencyReport?.value && (
                            <div className={S.energyWrapper}>
                                <DA_BasicLink href={url}>
                                    <img
                                        className={S.energyImage}
                                        src={props.data?.energyEfficiencyArrows?.value}
                                        alt=""
                                    />
                                </DA_BasicLink>
                                <DA_InlineLink
                                    className={S.energyLabel}
                                    href={props.data?.energyEfficiencyReport?.value}
                                    title={getDictionaryString("ProductSheet")}
                                    target="_blank"
                                />
                            </div>
                        )}

                    {/** Price */}
                    {showPrices && isAvailableToBuy ? (
                        <div>
                            {props.onSale && (
                                <DA_Badge
                                    className={cn(S.badge, S.saleBadge)}
                                    size="normal"
                                    text={getDictionaryString("offer splat")}
                                />
                            )}

                            <DA_ProductListPrice
                                price={props.price}
                                hasVariants={hasVariants}
                                onClick={openShippingInfoModal}
                            />
                        </div>
                    ) : null}

                    <div className={S.pushContent}>
                        {/** Quantity */}
                        {isAvailableToBuy ? (
                            <DA_QuantityPicker
                                value={quantity}
                                onChange={setQuantity}
                                compactOnMobile
                                higherMax={!isFarmInTimeEnabled}
                            />
                        ) : null}

                        {/** Button */}
                        <DA_CartButton
                            // title={getDictionaryString("add to cart")}
                            hasVariants={hasVariants}
                            hasHazardStatements={hasHazardStatement}
                            isAvailableToBuy={isAvailableToBuy}
                            productPage={url}
                            hideText={isMobileOrTablet}
                            addToCart={() =>
                                addProductToCart(
                                    modalViewState,
                                    quantityAsInt,
                                    totalPalletQuantity,
                                    numberOfPallets
                                )
                            }
                            showSpinner={awaitingResponse}
                            fullWidth={isMobileOrTablet}
                            className={S.cartSliderBuyBtn}
                        />
                    </div>
                </div>
            </div>
        </article>
    );
});

ProductListItem.displayName = "ProductListItem";
