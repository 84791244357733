import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_ButtonLink, DA_ButtonLinkProps } from "../../atoms/ButtonLink/ButtonLink.component";
import { DA_InlineLink } from "../../atoms/InlineLink/InlineLink.component";
import S from "./LinkList.module.scss";

interface DA_LinkListLink extends Omit<DA_ButtonLinkProps, "target"> {
    target?: "_blank" | "_self";
}

export interface DA_LinkListProps {
    links: DA_LinkListLink[];
    className?: string;
}

export const DA_LinkList = ({ links, className }: DA_LinkListProps) => (
    <ul className={cn(className, S.list)}>
        {links.map((link, index) => (
            <li key={index} className={S.item}>
                {link.onClick && (
                    <DA_ButtonLink
                        href={link.href}
                        title={link.title}
                        className={S.link}
                        onClick={link.onClick}
                    />
                )}

                {link.href && (
                    <DA_InlineLink
                        href={link.href}
                        title={link.title}
                        className={S.link}
                        target={link.target}
                    />
                )}
            </li>
        ))}
    </ul>
);
