import React from "react";
import { DA_ContentBox } from "@danishagro/shared/src/components/atoms/ContentBox/ContentBox.component";
import { DA_SwitchElement } from "@danishagro/shared/src/components/atoms/SwitchElement/SwitchElement.component";
import { DA_ProductTag, DA_ProductTagProps } from "@molecules/ProductTag/ProductTag.component";
import { DA_Tooltip } from "@danishagro/shared/src/components/atoms/Tooltip/Tooltip.component";
import S from "./UrgentDeliverySection.module.scss";

export interface _UrgentDeliverySectionProps {
    selected: string;
    texts: {
        icon: DA_ProductTagProps["icon"];
        title: string;
        text: string;
        titleColor: DA_ProductTagProps["color"];
    };
    options: {
        id: string;
        text: string;
        tooltip: string;
    }[];
    onChange?: (id: string) => void;
}

export const _UrgentDeliverySection = (props: _UrgentDeliverySectionProps) => {
    return (
        <DA_ContentBox smallVerticalPadding smallMargin>
            <div className={S.container}>
                <header className={S.header}>
                    <DA_ProductTag icon={props.texts.icon} color={props.texts.titleColor}>
                        {props.texts.title}
                    </DA_ProductTag>
                    <span dangerouslySetInnerHTML={{ __html: "&nbsp;" + props.texts.text }} />
                </header>

                <ul className={S.list}>
                    {props.options.map((option) => (
                        <li key={option.id} className={S.item}>
                            <label htmlFor={option.id} className={S.label}>
                                <div>
                                    {option.text}
                                    {option.tooltip ? (
                                        <DA_Tooltip text={option.tooltip} placement="top" />
                                    ) : null}
                                </div>
                                <div className={S.switch}>
                                    <DA_SwitchElement
                                        id={option.id}
                                        checked={option.id === props.selected}
                                        onChange={() => props.onChange(option.id)}
                                    />
                                </div>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </DA_ContentBox>
    );
};
