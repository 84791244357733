import React, { useCallback, useState } from "react";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_Input } from "@danishagro/shared/src/components/atoms/Input/Input.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useTranslations } from "../../../contexts/translations/translations.context";
import {
    DA_DynamicOverlayWrapper,
    useDynamicOverlay,
} from "../../../hooks/dynamicOverlay/useDynamicOverlay.hook";
import { useScreen } from "../../../contexts/screen.context";
import { useAddressFromSessionStorage } from "../../../hooks/useAddressFromSessionStorage.hook";
import { useAddressesApi } from "../../../hooks/api/useAddressesApi.hook";
import { DA_AddressOverlayProps } from "./AddressOverlay.props";
import { useValidation } from "./hooks/useValidation.hook";
import { AddressOverlayViewState } from "./interfaces/AddressOverlayViewState.interface";
import S from "./AddressOverlay.module.scss";

export const DA_AddressOverlay = (props: DA_AddressOverlayProps) => {
    const [addresses, setAddresses] = useState(props.addresses);
    const [awaitingResponse, setAwaitingResponse] = useState(false);

    const [selectedAddress, setSelectedAddress] = useState<DA_Address>(
        addresses.find(({ id }) => id === props.selectedAddress?.id)
    );

    const [customStreetName, setCustomStreetName] = useState(
        (!selectedAddress && props.selectedAddress?.streetName) || ""
    );
    const [customStreetNumber, setCustomStreetNumber] = useState(
        (!selectedAddress && props.selectedAddress?.streetNumber) || ""
    );
    const [customZip, setCustomZip] = useState(
        (!selectedAddress && props.selectedAddress?.zipCode) || ""
    );
    const [customCity, setCustomCity] = useState(
        (!selectedAddress && props.selectedAddress?.city) || ""
    );

    const [addressViewState, setAddressViewState] = useState<AddressOverlayViewState>(
        props.defaultViewState || AddressOverlayViewState.PickerAndCreateLink
    );

    const { getDictionaryItem, getDictionaryString } = useTranslations();
    const { isMobileOrTablet } = useScreen();
    const { closeOverlay } = useDynamicOverlay();
    const { addDeliveryAddress } = useAddressesApi();
    const { setAddressInSessionStorage } = useAddressFromSessionStorage();

    const { validationErrorMessages, validate } = useValidation(
        customStreetName,
        customStreetNumber,
        customZip,
        customCity
    );

    const onSubmitButtonClicked = useCallback(() => {
        if (
            addressViewState === AddressOverlayViewState.CreateForm ||
            addressViewState === AddressOverlayViewState.PickerAndCreateForm
        ) {
            const isValid = validate();

            if (isValid) {
                setAwaitingResponse(true);

                addDeliveryAddress({
                    streetName: customStreetName.trim(),
                    streetNumber: customStreetNumber.trim(),
                    zipCode: customZip.trim(),
                    city: customCity.trim(),
                    countryCode: "DK",
                })
                    .then((newAddress) => {
                        props.onSubmit(newAddress);
                        setAddressInSessionStorage(newAddress.id);
                        setAddresses((prev) => [...prev, newAddress]);

                        if (props.setAddresses) {
                            props.setAddresses((prev) => [...prev, newAddress]);
                        }
                    })
                    .finally(() => setAwaitingResponse(false));
            }
        } else if (selectedAddress) {
            setAwaitingResponse(true);
            props.onSubmit(selectedAddress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedAddress,
        validate,
        addDeliveryAddress,
        customStreetName,
        customStreetNumber,
        customZip,
        customCity,
        props.onSubmit,
        setAddressInSessionStorage,
    ]);

    const streetNameError = validationErrorMessages.find((x) => x.key === "streetName");
    const streetNumberError = validationErrorMessages.find((x) => x.key === "streetNumber");
    const zipCodeError = validationErrorMessages.find((x) => x.key === "zipCode");
    const cityError = validationErrorMessages.find((x) => x.key === "city");

    return (
        <DA_DynamicOverlayWrapper
            content={
                <div className={S.wrapper}>
                    {props.description ? (
                        <div className={S.description}>{props.description}</div>
                    ) : null}

                    <section>
                        {props.pickerTitle ? (
                            <div className={S.addressTitle}>{props.pickerTitle}</div>
                        ) : null}

                        {addressViewState === AddressOverlayViewState.Picker ||
                        addressViewState === AddressOverlayViewState.PickerAndCreateLink ||
                        addressViewState === AddressOverlayViewState.PickerAndCreateForm ? (
                            <div className={S.addresses}>
                                {addresses?.map((address) => {
                                    const isCheckedClass =
                                        address.id === selectedAddress?.id
                                            ? S.isChecked
                                            : undefined;

                                    return (
                                        <label
                                            key={address.id}
                                            className={cn(S.address, isCheckedClass)}
                                        >
                                            {/** Hidden Input */}
                                            <input
                                                type="radio"
                                                name="address-overlay-radio"
                                                value={address.id}
                                                onChange={() => {
                                                    setSelectedAddress(address);
                                                    if (props.defaultViewState) {
                                                        setAddressViewState(props.defaultViewState);
                                                    }
                                                }}
                                                checked={address.id === selectedAddress?.id}
                                            />

                                            {/** Visual Input */}
                                            <div className={S.radioButton} />

                                            {/** Label */}
                                            <span>
                                                {address.streetName} {address.streetNumber},{" "}
                                                {address.zipCode} {address.city}
                                            </span>
                                        </label>
                                    );
                                })}
                            </div>
                        ) : null}
                    </section>

                    {addressViewState === AddressOverlayViewState.PickerAndCreateForm ||
                    addressViewState === AddressOverlayViewState.CreateForm ? (
                        <div className={S.addAddress}>
                            <div className={S.addressForm}>
                                <div className={S.street}>
                                    <div className={S.streetName}>
                                        <DA_Input
                                            label={getDictionaryItem("streetName")}
                                            id="address-overlay-street-name"
                                            invalid={!!streetNameError}
                                            value={customStreetName}
                                            onChange={(newValue) => setCustomStreetName(newValue)}
                                        />
                                    </div>
                                    <div className={S.streetNumber}>
                                        <DA_Input
                                            label={getDictionaryItem("streetNumber")}
                                            id="address-overlay-street-number"
                                            invalid={!!streetNumberError}
                                            value={customStreetNumber}
                                            onChange={(newValue) => setCustomStreetNumber(newValue)}
                                        />
                                    </div>
                                </div>
                                <div className={S.zipCity}>
                                    <div className={S.zip}>
                                        <DA_Input
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            type="number"
                                            label={getDictionaryItem("zip")}
                                            id="address-overlay-zip"
                                            invalid={!!zipCodeError}
                                            value={customZip}
                                            onChange={(newValue) => setCustomZip(newValue)}
                                        />
                                    </div>
                                    <div className={S.city}>
                                        <DA_Input
                                            label={getDictionaryItem("city")}
                                            id="address-overlay-city"
                                            invalid={!!cityError}
                                            value={customCity}
                                            onChange={(newValue) => setCustomCity(newValue)}
                                        />
                                    </div>
                                </div>

                                {/** Validation */}
                                {validationErrorMessages?.length > 0 ? (
                                    <div className={S.errorMessage}>
                                        {validationErrorMessages?.[0].value}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : null}

                    {addressViewState === AddressOverlayViewState.PickerAndCreateLink ? (
                        <div className={S.inputAddressButton}>
                            <DA_ButtonLink
                                title={getDictionaryString("inputAddress")}
                                onClick={() => {
                                    setSelectedAddress(undefined);
                                    setAddressViewState(
                                        AddressOverlayViewState.PickerAndCreateForm
                                    );
                                }}
                                icon={DA_IconNames.Add}
                            />
                        </div>
                    ) : null}
                </div>
            }
            footer={
                <div className={S.buttons}>
                    {/** Submit */}
                    <DA_Button
                        title={props.submitText || getDictionaryString("add")}
                        onClick={onSubmitButtonClicked}
                        disabled={awaitingResponse}
                        showSpinner={awaitingResponse}
                        fullWidth={isMobileOrTablet}
                    />

                    {/** Cancel */}
                    <DA_Button
                        title={getDictionaryString("cancel")}
                        onClick={closeOverlay}
                        isGhost
                        disabled={awaitingResponse}
                        fullWidth={isMobileOrTablet}
                    />
                </div>
            }
        />
    );
};
