import { useCallback } from "react";
import { useAppData } from "../../contexts/appData.context";
import { useRequest } from "../useRequest.hook";
import { DA_Address } from "../../interfaces/address.interface";
import { FetchOptions } from "../../interfaces/fetchOptions.interface";

export const useAddressesApi = () => {
    const { customerNumber } = useAppData();
    const request = useRequest();

    const addDeliveryAddress: (
        address: Omit<DA_Address, "id" | "locationId">,
        options?: FetchOptions
    ) => Promise<DA_Address> = useCallback(
        (address, options) =>
            request
                .post(`addresses/${customerNumber}/delivery`, address, options)
                .then((response) => response.json()),
        [request, customerNumber]
    );

    const getDeliveryAddresses: (options?: FetchOptions) => Promise<DA_Address[]> = useCallback(
        (options) => request.get(`addresses/${customerNumber}/delivery`, undefined, options),
        [request, customerNumber]
    );

    return { addDeliveryAddress, getDeliveryAddresses };
};
